<template>
    <PublicLayout>
        <v-container class="d-flex justify-center align-center" style="height: 100%">
            <p class="text-center" style="max-width: 500px">
                {{ $store.getters.settings.about }}
            </p>
        </v-container>
    </PublicLayout>
</template>

<script>
export default {
    name: "About",
    components: { PublicLayout: () => import("@/layouts/public/Index.vue") },
    created() {
        this.$store.dispatch("setSettings");
    },
};
</script>
